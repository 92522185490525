<template>
  <div class="page">
    <b-overlay
      :show="loadingDatasetsPage"
      :variant="'white'"
      :opacity="0.7"
      :blur="'2px'"
      rounded="sm"
      no-wrap
    />
    <div
      class="back-link"
      @click="$router.push({ name: 'Data' })"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ $t('datasets.data') }}
      </span>
    </div>
    <div>
      <div class="top">
        <b-row>
          <b-col class="left-col">
            <span>
              <img
                src="@/assets/icons/files_ressources.svg"
                alt="Icon files resources"
              >
              {{ datasetsCount }} {{ $tc('words.dataset', datasetsCount) }}
            </span>
          </b-col>
          <b-col class="right-col vertical">
            <button
              class="export-button"
              @click="exportDatasetToODL"
            >
              {{ capitalizeFirstLetter($t('words.export')) }}
              <i class="bi bi-file-earmark-arrow-down" />
            </button>
          </b-col>
        </b-row>
      </div>
      <div class="content">
        <SwitchButton
          v-if="userData.is_superuser"
          :active="{
            label: $t('datasets.seeAll'),
            value: null
          }"
          :disabled="{
            label: $t('datasets.seeAll'),
            value: 'change_dataset'
          }"
          :initial-value="false"
          @toggle="setSeeAllFilter"
        />
        <div class="row">
          <div class="col-3">
            <DatasetsFilters
              @set="setDatasetsFilters"
            />
          </div>
          <div class="col-9">
            <DatasetsList
              @sort-by="$event => sortBy = $event"
              @get-data="getData"
              @change-page="changePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatasetsFilters from '@/components/Dataset/DatasetsFilters.vue';
import DatasetsList from '@/components/Dataset/DatasetsList.vue';
import SwitchButton from '@/components/Elements/SwitchButton.vue';

import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'Datasets',

  components: {
    DatasetsFilters,
    DatasetsList,
    SwitchButton
  },

  data() {
    return {
      initiating: true,
      loadingDatasetsPage: false,

      isSeeAllChecked: false,
      sortBy: {
        direction: '',
        field: ''
      },

      datasetToDelete: null,
      isDeleteDatasetWithResourceOpen: false,
      datasetWithResourceModalData: {
        name: null,
        nbResources: null
      }
    };
  },

  computed: {
    ...mapState('user-login', [
      'userData'
    ]),
    ...mapState('datasets', [
      'currentDataset',
      'datasetsList',
      'datasetsCount',
      'isDatasetsListSearched'
    ]),
    ...mapState('table', [
      'currentTables'
    ]),
  },

  watch: {
    sortBy: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.getData();
        }
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === 'DatasetPermissions') {
        vm.RESET_USERGROUPS_PERMISSIONS_FILTERS('usergroup_type_id');
        vm.RESET_USERGROUPS_PERMISSIONS_FILTERS('organisation_type_id');
        vm.RESET_USERGROUPS_PERMISSIONS_FILTERS('level');
      }
    });
  },

  created() {
    this.RESET_DATASETS_FILTERS();
    // Clean stored form
    this.SET_CURRENT_FORM({
      id: null,
      form: null
    });
  },

  mounted() {
    // TODO: enlever table page
    if (!this.isDatasetsListSearched) {
      this.getData(
        this.currentTables.find(t => t.name === 'datasetsListTable') ?
          this.currentTables.find(t => t.name === 'datasetsListTable').currentPage :
          1
      );
    }

    // Watch modal closing event
    this.$root.$on('bv::modal::hide', () => {
      this.isDeleteDatasetWithResourceOpen = false;
    });

    this.initiating = false;
  },

  beforeRouteLeave(to, from, next) {
    this.RESET_DATASETS_FILTERS();
    next();
  },

  methods: {
    ...mapMutations('table', [
      'SET_TABLE'
    ]),
    ...mapMutations('form', [
      'SET_CURRENT_FORM'
    ]),
    ...mapMutations('datasets', [
      'RESET_USERGROUPS_PERMISSIONS_FILTERS',
      'SET_DATASETS_FILTERS',
      'RESET_DATASETS_FILTERS'
    ]),
    ...mapActions('datasets', [
      'GET_DATASETS_LIST',
      'EXPORT_DATASET_LIST'
    ]),

    async getData(page) {
      try {
        this.loadingDatasetsPage = true;
        await this.GET_DATASETS_LIST({ ...this.sortBy, page: page });
        this.loadingDatasetsPage = false;
      } catch (err) {
        if (err && err.code && err.code !== 'ERR_CANCELED') {
          this.loadingDatasetsPage = false;
        }
      }
    },

    changePage(e) {
      this.getData(e);
    },

    setSeeAllFilter(value) {
      this.setDatasetsFilters({
        filter: 'perms',
        value: value
      });
    },

    setDatasetsFilters(e) {
      this.SET_DATASETS_FILTERS({
        filter: e.filter,
        value: e.value
      });
      if (!this.initiating) {
        this.getData();
      }
    },

    async exportDatasetToODL() {
      this.loadingDatasetsPage = true;
      await this.EXPORT_DATASET_LIST();
      this.loadingDatasetsPage = false;
    }
  }
};
</script>

<style lang="less" scoped>
.datasets-sort-selector {
  width: max-content;
  min-width: 245px;
}
.content {
  margin: 1rem 2.5rem 1rem 2.4rem;
  padding-right: 5px;
}
</style>
