<template>
  <div class="datasets-filters">
    <div>
      <!-- USERGROUP -->
      <label for="">
        {{ capitalizeFirstLetter($tc('words.organisation')) }} / {{ capitalizeFirstLetter($tc('words.userGroup')) }}
      </label>
      <SearchUsergroups
        v-model="selectedUsergroup"
        :types="['organisation', 'user-group']"
        :placeholder="$t('forms.datasets.organisation.help')"
        :usergroup="selectedUsergroup"
        :reset-after="false"
        :limit="10"
        @select="selectUsergroup"
        @remove="selectUsergroup"
      />
    </div>

    <!-- RECORD TYPE -->
    <!-- <div>
      <label for="">
        Type de fiche
      </label>
    </div>
    <Multiselect
      :options="datasetsRecordTypes"
      track-by="choice"
      label="label"
      select-label=""
      selected-label=""
      deselect-label=""
      :searchable="false"
      :placeholder="$t('datasetDetail.recordType')"
      @select="selectRecordType"
    /> -->

    <!-- PUBLICATION -->
    <div>
      <label for="">
        {{ $t('datasets.publishedInCatalog') }}
      </label>
      <div class="form-check">
        <input
          id="publishedDatasetFilter"
          v-model="isPublishChecked"
          class="form-check-input"
          type="checkbox"
          value=""
          @change="setPublicationFilter"
        >
        <label
          class="form-check-label"
          for="publishedDatasetFilter"
        >
          {{ $t('words.published') }}
        </label>
      </div>
      <div class="form-check">
        <input
          id="unpublishedDatasetFilter"
          v-model="isUnpublishChecked"
          class="form-check-input"
          type="checkbox"
          value=""
          @change="setPublicationFilter"
        >
        <label
          class="form-check-label"
          for="unpublishedDatasetFilter"
        >
          {{ $t('words.unpublished') }}
        </label>
      </div>
    </div>

    <!-- EDITABLE -->
    <!-- <div>
      <label for="">
        Droits d'édition
      </label>
      <div class="form-check">
        <input
          id="editableDatasetFilter"
          v-model="isEditableChecked"
          class="form-check-input"
          type="checkbox"
          value=""
          @change="setEditableFilter"
        >
        <label
          class="form-check-label"
          for="editableDatasetFilter"
        >
          Jeux de données éditables uniquements
        </label>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

// import Multiselect from 'vue-multiselect';
import SearchUsergroups from '@/components/Searchbars/SearchUsergroups.vue';

export default {
  name: 'DatasetsFilters',

  components: {
    // Multiselect,
    SearchUsergroups
  },

  data() {
    return {
      selectedRecordTypes: [],
      selectedUsergroup: null,
      isPublishChecked: false,
      isUnpublishChecked: false,
      // isEditableChecked: true,
    };
  },

  computed: {
    ...mapState('user-login', [
      'userData'
    ]),
    ...mapState('datasets', [
      'datasetsRecordTypes'
    ])
  },

  async created() {
    if (!this.userData.is_superuser) {
      this.SET_USERGROUPS_FILTERS({
        filter: 'usergroup__status__in',
        value: 2
      });
      this.SET_USERGROUPS_FILTERS({
        filter: 'user__role__in',
        value: ['1', '2']
      });
    }
    if (this.datasetsRecordTypes.length === 0) {
      await this.GET_DATASETS_RECORD_TYPES();
    }
  },

  beforeRouteLeave(to, from, next) {
    this.RESET_USERGROUPS_FILTERS();
    next();
  },

  methods: {
    ...mapMutations('usergroups', [
      'SET_USERGROUPS_FILTERS',
      'RESET_USERGROUPS_FILTERS'
    ]),
    ...mapActions('datasets', [
      'GET_DATASETS_RECORD_TYPES'
    ]),

    selectUsergroup(e) {
      this.$emit('set', {
        filter: 'usergroup_id',
        value: e && e.id ? e.id : null
      });
    },

    // selectRecordType() {
    // },

    setPublicationFilter() {
      let value = null;
      if (this.isPublishChecked && !this.isUnpublishChecked) {
        value = 'true';
      }
      if (!this.isPublishChecked && this.isUnpublishChecked) {
        value = 'false';
      }
      this.$emit('set', {
        filter: 'publish',
        value: value
      });
    },

    // setEditableFilter() {
    //   let value = null;
    //   if (this.isEditableChecked) {
    //     value = 'change_dataset';
    //   }
    //   this.$emit('set', {
    //     filter: 'perms',
    //     value: value
    //   });
    // }
  }
};
</script>

<style lang="less" scoped>
.datasets-filters {
  padding: 1rem;
  box-shadow: 0 0 2px 1px #b9b9ba;
  border-radius: 2px;

  label:not(.form-check-label) {
    margin-top: 1rem;
    font-size: 0.9rem;
  }

  .form-check {
    margin: .5em 1rem .5em;
  }
}
</style>